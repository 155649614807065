import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Main from "../components/main";
import About from "../components/about";
import Services from "../components/services";
import Projects from "../components/projects";
import Partners from "../components/partners";
import Clients from "../components/clients";
import Contact from "../components/contact";

const IndexPage = ({ location }) => (
  <Layout location={location}>
    <SEO title="Главная страница" />
    <Main />
    <About />
    <Services />
    <Projects />
    <Partners />
    <Clients />
    <Contact />
  </Layout>
);

export default IndexPage;
