import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Carousel from "react-multi-carousel";

const Partners = () => {
  const data = useStaticQuery(
    graphql`
    {
      partners: allPartnersCsv {
        nodes {
          name
          url
          logos: files {
            childImageSharp {
              gatsbyImageData(formats: [AUTO, WEBP, AVIF])
            }
          }
        }
      }
    }
    `
  );
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 5000, min: 992 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 992, min: 576 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 576, min: 0 },
      items: 2
    },
  };

  return (
  <section id="partners" className="partners dev">
    <Container>
      <Row>
        <Col lg="6">
          <div className="section_title">
            <h4 className="title">Партнеры</h4>
          </div>
        </Col>
      </Row>
      <Row>
        <Carousel responsive={responsive} infinite={true} autoPlay={true} itemClass="partners__item">
       {
          data.partners.nodes.map((item, index) =>
              <a href={item.url} key={index}>
                <div className={`partners__logo partners__${item.name.replace(' ', '_')}`}>
                  <GatsbyImage className="mx-auto" image={getImage(item.logos[0])} alt={item.name}/>
                </div>
              </a>
          )
        }
        </Carousel>
        </Row>
    </Container>
  </section>
  )
};
export default Partners;
