import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Projects = () => (
  <section id="projects" className="projects dev">
    <Container>
      <Row>
        <Col lg="6">
          <div className="section_title">
            <h4 className="title">Проекты</h4>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);
export default Projects;
