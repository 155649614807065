import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const Clients = () => (
  <section id="clients" className="clients dev">
    <Container>
      <Row>
        <Col lg="6">
          <div className="section_title">
            <h4 className="title">Клиенты</h4>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);
export default Clients;
