import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { YMaps, Map, Placemark } from "react-yandex-maps";
import { BiPhone, BiMapAlt, BiEnvelope, BiGlobe } from "react-icons/bi";

const mapData = {
  center: [55.774224, 37.532358],
  zoom: 14,
};

const Contact = () => (
  <section id="contact" className="contact">
    <Container>
      <Row>
        <Col lg="6">
          <div className="section_title">
            <h4 className="title">Контакты</h4>
          </div>
        </Col>
      </Row>
      <Row className="contact__row">
        <Col lg="6">
          <div className="contact__group">
            <YMaps>
              <Map className="contact__map" defaultState={mapData}>
                <Placemark geometry={mapData.center} />
              </Map>
            </YMaps>
          </div>
        </Col>
        <Col lg="6">
          <div className="contact__group">
            <div className="contact__title">
              <h4 className="title">Коммерческий отдел</h4>
            </div>
            <div className="contact__info">
              <div className="contact__icon">
                <BiPhone />
              </div>
              <div className="contact__content">
                <p>+7 (495) 225-9561</p>
              </div>
            </div>
            <div className="contact__info">
              <div className="contact__icon">
                <BiEnvelope />
              </div>
              <div className="contact__content">
                <p>info@kuptehnik.ru</p>
              </div>
            </div>
          </div>

          <div className="contact__group">
            <div className="contact__title">
              <h4 className="title">Отдел сервисного обслуживания</h4>
            </div>
            <div className="contact__info">
              <div className="contact__icon">
                <BiPhone />
              </div>
              <div className="contact__content">
                <p>+7 (495) 225-9571</p>
              </div>
            </div>
            <div className="contact__info">
              <div className="contact__icon">
                <BiEnvelope />
              </div>
              <div className="contact__content">
                <p>service@kuptehnik.ru</p>
              </div>
            </div>
          </div>

          <div className="contact__group">
            <div className="contact__info">
              <div className="contact__icon">
                <BiGlobe />
              </div>
              <div className="contact__content">
                <p>www.kuptehnik.ru</p>
              </div>
            </div>

            <div className="contact__info">
              <div className="contact__icon">
                <BiMapAlt />
              </div>
              <div className="contact__content">
                <p>
                  2-й Хорошёвский проезд, 7к1, офис 413
                  <br />
                  Москва, Россия, 123007
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  </section>
);
export default Contact;
